




import { defineComponent, computed } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Summary',
  components: {
    DeskSummary: () => import('../desk/summary.vue'),
    MobileSummary: () => import('../mobile/summary.vue')
  },
  setup() {
    const summaryType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskSummary' : 'MobileSummary'
    )
    return {
      summaryType
    }
  }
})
