




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Honor',
  components: {
    DeskHonor: () => import('../desk/honor.vue'),
    MobileHonor: () => import('../mobile/honor.vue')
  },
  setup() {
    const honorType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskHonor' : 'MobileHonor'
    )
    return {
      honorType
    }
  }
})
