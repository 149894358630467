










import { defineComponent, onMounted, ref, nextTick } from '@vue/composition-api'
import Banner from './page/banner.vue'
import Summarys from './page/summary.vue'
import Tech from './page/tech.vue'
import Core from './page/core.vue'
import Honor from './page/honor.vue'

export default defineComponent({
  name: 'Introduct',
  components: { Banner, Summarys, Tech, Core, Honor },
  setup() {
    const show = ref<boolean>(false)
    onMounted(() => {
      nextTick(() => (show.value = true))
    })
    return {
      show
    }
  }
})
