




import { defineComponent, computed } from '@vue/composition-api'

import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Tech',
  components: {
    DeskTech: () => import('../desk/tech.vue'),
    MobileTech: () => import('../mobile/tech.vue')
  },
  setup() {
    const techType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskTech' : 'MobileTech'
    )
    return {
      techType
    }
  }
})
