




import { defineComponent, computed } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Banner',
  components: {
    DeskBanner: () => import('../desk/banner.vue'),
    MobileBanner: () => import('../mobile/banner.vue')
  },
  setup() {
    const bannerType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskBanner' : 'MobileBanner'
    )
    return {
      bannerType
    }
  }
})
